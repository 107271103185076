body {
    font-size: 12px;
}
.table-row-selected {
    background-color: #3498ff;
    color: white;
}
.rs-table-cell-header-icon-sort {
    color: white;
}
.agora-selected {
    background-color: #ffb300;
    color: white;
}
.modal-dialog-pending {
    border: 4px;
    border-style: solid;
    border-color: #f6c344;
    border-radius: 6px;
    margin: auto;
    opacity: 1;
}

.modal-dialog-resolve {
    border: 4px;
    border-style: solid;
    border-color: #67ad5b;
    border-radius: 6px;
    margin: auto;
    opacity: 1;
}
/* .rs-table-body-row-wrapper {
     height: 100% !important;
}
 .rdt_TableHeadRow {
     background-color: #3366cc !important;
     color: white;
}
 .rdt_Table {
     height: 70vh;
     max-height: 70vh;
}
 */
.image-loading {
    width: 90px;
    height: 120px;
    background-color: white;
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
}
@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}
@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.blink-note-selected {
  animation-name: blink;
  animation-duration: 5s;
  animation-timing-function: ease-in;
  animation-iteration-count: 3;

  -webkit-animation-name:blink;
  -webkit-animation-duration: 5s;
  -webkit-animation-timing-function: ease-in;
  -webkit-animation-iteration-count: 3;
}

@-moz-keyframes blink{  
  0% { opacity: 1.0; }
  50% { opacity: 0.3; }
  100% { opacity: 1.0; }
}

@-webkit-keyframes blink {  
  0% { opacity: 1.0; }
  50% { opacity: 0.3; }
   100% { opacity: 1.0; }
}

@keyframes blink {  
  0% { opacity: 1.0; }
   50% { opacity: 0.3; }
  100% { opacity: 1.0; }
}
/* :root {
     --rs-primary-500: #3366cc 
}
 */
.backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
}
.panel-header {
    font-size: 14px;
    color: #444444;
    letter-spacing: 0px;
}

.rs-panel-body {
    padding: 10px;
}

.inbox_msg {
    clear: both;
    overflow: hidden;
}

.received_msg {
    display: inline-block;
    padding: 0 0 0 10px;
    vertical-align: top;
    width: 92%;
    border: 1px solid #444444;
    border-radius: 4px;
    margin: 10px 0 2px;
}
.received_msg p {
    /*background: #e4e8fb none repeat scroll 0 0;
     border-radius: 3px;
    */
    color: #444444;
    font-size: 12px;
    margin: 0;
    padding: 5px 10px 5px 12px;
    width: 100%;
}
.time_date {
    color: #747474;
    display: block;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    margin: 3px 0 0;
}
.sent_msg p {
    /*background: #3F51B5 none repeat scroll 0 0;
     border-radius: 3px;
    */
    font-size: 12px;
    color: #444444;
    padding: 5px 10px 5px 12px;
    width: 100%;
}
.outgoing_msg {
    overflow: hidden;
    /*margin:5px 0 20px;*/
}
.sent_msg {
    float: right;
    text-align: left;
    width: 92%;
    border: 1px solid #444444;
    border-radius: 4px;
    padding: 0 0 0 10px;
    margin: 10px 0 2px;
}

.messaging {
    background: #fff;
}
.msg_history {
    max-height: 400px;
    overflow-y: auto;
}

.btnReload {
    background-color: #4364C1;
    color: #FFFFFF;
    margin-left: 30px;
}

.btnReload:hover {
    background-color: #2742A8;
    color: #FFFFFF;
}